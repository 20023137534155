<div class="dialog-header">
  <h2 mat-dialog-title>
    {{ data.title || defaultData.title }}
  </h2>
</div>

<button mat-icon-button class="close-button" (click)="close()">
  <mat-icon>close</mat-icon>
</button>

<mat-dialog-content class="selection-dialog-content">
  @if (data.enableSearch) {
    <mat-form-field appearance="outline" class="selection-search-form">
      <mat-icon matPrefix fontSet="material-icons-outlined">search</mat-icon>
      <input
        matInput
        type="text"
        cdkFocusInitial
        placeholder="Search"
        [(ngModel)]="keyword" />
    </mat-form-field>
  }

  <ul class="selection-list">
    @if (data.multi && !keyword()) {
      <li (click)="toggleAll()" class="selection-list-item">
        <label class="list-item-label">Select All</label>
        <input
          type="checkbox"
          [class.multi-select]="data.multi"
          [class.single-select]="!data.multi"
          [disabled]="!data.options.length"
          [checked]="selectionModel.selected.length === data.options.length" />
      </li>
    }
    @for (item of displayedOptions(); track $index) {
      <li (click)="select(item.key)" class="selection-list-item">
        <label class="list-item-label">{{ item.label }}</label>
        <input
          type="checkbox"
          [class.multi-select]="data.multi"
          [class.single-select]="!data.multi"
          [checked]="selectionModel.isSelected(item.key)" />
      </li>
    }
  </ul>
</mat-dialog-content>

@if (selectionModel.isMultipleSelection()) {
  <mat-dialog-actions class="selection-dialog-actions">
    <a class="cancel-button" (click)="close()">
      <span class="label">
        {{ data.cancelLabel || defaultData.cancelLabel }}
      </span>
    </a>
    <button mat-flat-button class="confirm-button" (click)="submit()">
      {{ data.confirmLabel || defaultData.confirmLabel }}
    </button>
  </mat-dialog-actions>
}
