import { A11yModule } from "@angular/cdk/a11y";
import { SelectionModel } from "@angular/cdk/collections";
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewEncapsulation,
  computed,
  signal,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";

export interface SelectionOption {
  key: string;
  label: string;
}

export interface SelectionsDialogData {
  title: string;
  confirmLabel: string;
  cancelLabel: string;
  selected: string[] | string;
  options: SelectionOption[];
  multi?: boolean;
  enableSearch?: boolean;
}

const SELECTIONS_DIALOG_DEFAULT_DATA: SelectionsDialogData = {
  title: "Add Selections",
  confirmLabel: "Submit",
  cancelLabel: "Cancel",
  enableSearch: true,
  multi: false,
  selected: [],
  options: [],
};

// TODO: move this dialog to shared

@Component({
  standalone: true,
  selector: "app-selection-dialog",
  host: { class: "roam-selection-dialog" },
  styleUrl: "./selections-dialog.component.scss",
  templateUrl: "./selections-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    A11yModule,
  ],
})
export class SelectionsDialogComponent {
  protected defaultData = SELECTIONS_DIALOG_DEFAULT_DATA;
  readonly selectionModel = new SelectionModel<string>(!!this.data.multi, []);
  readonly keyword = signal("");
  protected displayedOptions = computed(() => {
    return this.data.options.filter(opt => {
      return opt.label.toLowerCase().includes(this.keyword());
    });
  });

  toggleAll = (): void => {
    const options = this.data.options;
    if (options.length === this.selectionModel.selected.length) {
      this.selectionModel.clear();
    } else {
      const keys = options.map(item => item.key);
      this.selectionModel.select(...keys);
    }
  };

  select(key: SelectionOption["key"]): void {
    if (this.selectionModel.isMultipleSelection()) {
      this.selectionModel.toggle(key);
    } else {
      this.selectionModel.select(key);
      this.submit();
    }
  }

  submit = (): void => {
    this.dialogRef.close(this.selectionModel.selected);
  };

  close = (): void => {
    this.dialogRef.close();
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: SelectionsDialogData,
    public dialogRef: MatDialogRef<SelectionsDialogComponent>
  ) {
    this.data = { ...this.defaultData, ...data };
    const selected = this.data.selected || "";
    if (Array.isArray(selected)) {
      this.selectionModel.setSelection(...selected);
    } else {
      this.selectionModel.setSelection(selected);
    }
  }
}
